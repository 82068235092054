var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "900px", scrollable: "" },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline grey lighten-2 d-flex justify-space-between px-5 py-3 mb-5"
            },
            [
              _c("h5", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("selectCamp.chooseCamp")))
              ]),
              _c("v-icon", { on: { click: _vm.close } }, [_vm._v("mdi-close")])
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pa-5" },
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-form",
                    {
                      staticClass: "adgroup",
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", sm: "4" }
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  label: "Search by name or ID",
                                  "prepend-inner-icon": "mdi-magnify"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", sm: "4" }
                            },
                            [
                              _c("h5", { staticClass: "pa-2" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("selectCamp.noSelectedCamp"))
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center d-flex align-center justify-space-between"
                                },
                                [
                                  _c("h5", [
                                    _vm._v(
                                      _vm._s(_vm.totalCampaigns) +
                                        " " +
                                        _vm._s(_vm.$t("selectCamp.campaigns"))
                                    )
                                  ]),
                                  this.panel.length == 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "info--text",
                                          attrs: { depressed: "", text: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.expandAll()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-arrow-collapse-down ")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("selectCamp.expandAll")
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  this.panel.length > 0
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "info--text",
                                          attrs: { depressed: "", text: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.collapseAll()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "mr-1",
                                              attrs: { small: "" }
                                            },
                                            [_vm._v("mdi-arrow-collapse-up")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("selectCamp.collapseAll")
                                              ) +
                                              " "
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "py-0",
                              attrs: { cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "selected-groups-col d-flex align-center justify-space-between mt-1"
                                },
                                [
                                  _c("h5", [
                                    _vm._v(
                                      _vm._s(_vm.totalSelectedCampaigns) +
                                        " " +
                                        _vm._s(
                                          _vm.$t("selectCamp.selectedCampaigns")
                                        )
                                    )
                                  ]),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        depressed: "",
                                        text: "",
                                        small: "",
                                        color: "info"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.clearAll()
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("mdi-window-close")
                                      ]),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("selectCamp.clearAll")
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        { staticClass: "ma-0" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", sm: "4" }
                            },
                            [
                              [
                                _c(
                                  "v-expansion-panels",
                                  {
                                    attrs: { multiple: "" },
                                    model: {
                                      value: _vm.panel,
                                      callback: function($$v) {
                                        _vm.panel = $$v
                                      },
                                      expression: "panel"
                                    }
                                  },
                                  _vm._l(_vm.appsListWithCampaigns, function(
                                    app,
                                    i
                                  ) {
                                    return _c(
                                      "v-expansion-panel",
                                      { key: i },
                                      [
                                        _c(
                                          "v-expansion-panel-header",
                                          [
                                            _c("v-img", {
                                              staticClass: "mr-3",
                                              attrs: {
                                                "max-height": "22",
                                                "max-width": "22"
                                              }
                                            }),
                                            _vm._v(
                                              " " + _vm._s(app.appName) + " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "no float-right text-right"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(app.campaigns.length)
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-expansion-panel-content",
                                          [
                                            _c(
                                              "v-card",
                                              {
                                                staticClass: "mx-auto",
                                                attrs: {
                                                  outlined: "",
                                                  "max-width": "300",
                                                  tile: ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-list",
                                                  { attrs: { dense: "" } },
                                                  [
                                                    _c(
                                                      "v-list-item-group",
                                                      {
                                                        attrs: {
                                                          color: "primary"
                                                        }
                                                      },
                                                      _vm._l(
                                                        app.campaigns,
                                                        function(campaign) {
                                                          return _c(
                                                            "v-list-item",
                                                            {
                                                              key: campaign.id,
                                                              attrs: {
                                                                selectable: true
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.listCampaign(
                                                                    campaign
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item-content",
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        {
                                                                          domProps: {
                                                                            textContent: _vm._s(
                                                                              campaign.name
                                                                            )
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          campaign.status ==
                                                                          "PAUSED"
                                                                            ? _c(
                                                                                "v-chip",
                                                                                {
                                                                                  staticClass:
                                                                                    "ma-0",
                                                                                  attrs: {
                                                                                    "x-small":
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Paused "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            : _vm._e()
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                )
                              ]
                            ],
                            2
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "mx-auto d-flex pa-4",
                                  attrs: {
                                    outlined: "",
                                    "max-width": "400",
                                    height: "100%",
                                    tile: ""
                                  }
                                },
                                [
                                  _vm.listedCampaign
                                    ? _c(
                                        "div",
                                        { staticClass: "boxes" },
                                        [
                                          _c("v-checkbox", {
                                            key: _vm.listedCampaign.id,
                                            staticClass: "mt-0",
                                            attrs: {
                                              label: _vm.listedCampaign.name,
                                              color: "success",
                                              "hide-details": "",
                                              disabled: !_vm.listedCampaign
                                                .keywords_exist
                                            },
                                            on: {
                                              change: function($event) {
                                                return _vm.oncheck(
                                                  _vm.listedCampaign
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.listedCampaign.selected,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.listedCampaign,
                                                  "selected",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "listedCampaign.selected"
                                            }
                                          }),
                                          _c(
                                            "span",
                                            [
                                              !_vm.listedCampaign.keywords_exist
                                                ? _c(
                                                    "v-chip",
                                                    {
                                                      staticClass: "ma-0",
                                                      attrs: { "x-small": "" }
                                                    },
                                                    [_vm._v(" No Keywords ")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "pa-0",
                              attrs: { cols: "12", sm: "4" }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "mx-auto d-flex flex-column pa-4",
                                  attrs: {
                                    outlined: "",
                                    "max-width": "400",
                                    height: "100%",
                                    tile: ""
                                  }
                                },
                                [
                                  _vm._l(_vm.appsListWithCampaigns, function(
                                    app
                                  ) {
                                    return [
                                      _vm._l(app.campaigns, function(campaign) {
                                        return [
                                          campaign.selected
                                            ? _c("label", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(campaign.name) +
                                                    " "
                                                )
                                              ])
                                            : _vm._e(),
                                          campaign.selected
                                            ? _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    width: "32",
                                                    depressed: "",
                                                    "x-small": "",
                                                    fab: "",
                                                    text: "",
                                                    color: "error"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteCamp(
                                                        campaign
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { small: "" } },
                                                    [
                                                      _vm._v(
                                                        "mdi-delete-outline "
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      })
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-3 pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "grey",
                    depressed: "",
                    outlined: ""
                  },
                  on: { click: _vm.close }
                },
                [_vm._v(" " + _vm._s(_vm.$t("selectCamp.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { width: "127", color: "success", depressed: "" },
                  on: { click: _vm.save }
                },
                [_vm._v(" " + _vm._s(_vm.$t("selectCamp.apply")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }